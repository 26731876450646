<template>
  <v-app>
    <v-main >
      <router-view />

      <!-- <dashboard-core-footer /> -->
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "Auth",

  components: {},

  data: () => ({
    expandOnHover: false,
  }),
};
</script>
<style scoped>
/* .main {
  background-image: url("/src/assets/dental.jpg");
  background-position: center center;
  background-size: contain;
} */
</style>
